import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { BOY_NAME_SHORT, GIRL_NAME_SHORT, IS_BOY_FIRST } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';

import useInvitation from '@hooks/useInvitation';
import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';

// const TEXT_SHADOW = 'rgba(12,12,12,.8)';

function LeftContent() {
  const isInvitation = useInvitation();

  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      bgPos="center"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
      //boxShadow="inset 0 0 0 2000px rgb(255 255 255 / 30%)"
    >
      <Box bgColor={BG_SECONDARY_TRANSPARENT} height="100%" padding="42px">
        <WithAnimation>
          <Text color="mainColorText" letterSpacing="2px" fontSize="md" fontWeight="light">
            {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
          </Text>
        </WithAnimation>
        <Box
          padding="24px 0"
          textColor="mainColorText"
          textTransform="uppercase"
          letterSpacing="4px"
        >
          <Heading fontWeight="normal" size="4xl">
            {IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT} & {IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT}
          </Heading>          
        </Box>
        <WithAnimation>
          <Text
            color="mainColorText"
            fontWeight="light"
            fontStyle="italic"
            maxWidth="800px"
            letterSpacing="1px"
            textShadow="1px 1px 1px white"
          >
            I know the color of love<br />
            And it lives inside of you <br />
            I know the color of truth <br />
            {`It's in the image of you`} <br />
            If it comes from the heart <br />
            {`Then you know that it's true`} <br />
            It will color your soul like a rainbow  <br />
            And the color of love is in you <br />
            <br />
            -Boyz II Men-
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;
